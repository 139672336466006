import React, { Component, Fragment } from 'react'
import { Container, Navbar, Nav,NavDropdown } from 'react-bootstrap'
import '../TopNavigation/TopNavigation.css'
import Logo from '../../asset/image/logo.png'
import LogoScroll from '../../asset/image/menaScroll.png'

import {NavLink} from "react-router-dom"


 export class TopNavigation extends Component {

     constructor() {
          super();
          this.state = {
               navBarTitle: 'navTitle',
               logo: [Logo],
               NavbarBack:"navBackground" ,
               NavbarItems :"navItem" ,
               variant:"dark"


          }
     }

     Scroll = () => {
          if (window.scrollY > 100) {
               this.setState({ navBarTitle: 'navTitleScroll', logo: [LogoScroll] ,NavbarBack:'navBackgroundScroll' ,NavbarItems:'navItemScroll' ,  variant:"light"})
              
          } else {
               this.setState({ navBarTitle: 'navTitle', logo: [Logo] ,NavbarBack:'navBackground', NavbarItems:'navItem' ,variant:"dark" })
          }
     }

     componentDidMount() {
          window.addEventListener('scroll', this.Scroll);
     }
     
     render() {
          return (
               <Fragment>
                    <Navbar fixed="top" collapseOnSelect expand="lg" className={this.state.NavbarBack} >
                    
                              <Navbar.Brand href="/" className={this.state.navBarTitle}> <img src={this.state.logo} />  منة الله علاء
</Navbar.Brand>
                              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                              <Navbar.Collapse id="responsive-navbar-nav">
                                   <Nav className="me-auto">
                                   </Nav>
                                   <Nav    >
                                  
                                   <Nav.Link className={this.state.NavbarItems}  href="https://menatullah.art/work/baners.html">لوجو وبانرز</Nav.Link> 
                                   <Nav.Link className={this.state.NavbarItems}  href="https://menatullah.art/work/cards.html">بيزنس كارت</Nav.Link> 
                                   <Nav.Link className={this.state.NavbarItems}  href="https://menatullah.art/work/clothes.html">رسم علي الجينز</Nav.Link> 
                                   <Nav.Link className={this.state.NavbarItems}  href="https://menatullah.art/work/child.html">رسومات  أطفال</Nav.Link> 
                                   <Nav.Link className={this.state.NavbarItems}  href="http://menatullah.art">الرئيسية</Nav.Link> 
                                 
                                   </Nav>
                              </Navbar.Collapse>
                                    
                    </Navbar>
               </Fragment>
          )
     }
}
export default TopNavigation
