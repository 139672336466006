import React, { Component ,Fragment} from 'react'

import TopNavigation from '../components/TopNavigation/TopNavigation'
import TopPage from './TopPage'
import Footer from '../components/Footer/Footer'

// import AllProject from '../components/AllProject/AllProject'
import{Link} from 'react-router-dom'

import { Container, Row, Col, Card, Button } from 'react-bootstrap'

import SerImg1 from '../asset/image/Services-1.jpg'
import SerImg2 from '../asset/image/Services-2.jpg'
import SerImg3 from '../asset/image/Services-3.jpg'
import SerImg4 from '../asset/image/Services-4.jpg'

import '../asset/css/Bootstrap.min.css'

export class ProjectPage extends Component {
     componentDidMount(){
          window.scroll(0,0)
      }

     render() {
          return (
              <Fragment>

              <TopNavigation/>
              <TopPage pagetitle="All Project"/>
              {/* <AllProject/> */}

                       
              <Footer/>
     
      

                    <Container >


                         <Row>

                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg1} className="ServicesIcon" />                                   </div>

                              </Col>

                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg2} className="ServicesIcon" />
                                   </div>

                              </Col>
                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg3} className="ServicesIcon" />
                                             </div>
                                             </Col>

                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg4} className="ServicesIcon" />
                                   </div>

                              </Col>

                         </Row>

                    </Container>
               </Fragment>
          )
     }
}

export default ProjectPage
