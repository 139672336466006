import React, { Component } from 'react'
import { Fragment } from 'react'

import'../../asset/css/custom.css'
import {Container, Row, Col, Button} from 'react-bootstrap'
import'../../asset/css/Bootstrap.min.css'


export class TopBanner extends Component {
  render() {
    return (
        <Fragment>

        <Container fluid={true}  className="topFixedBanner p-0">

        <div className="topBannerOverly"> 
        
        <Container className='topContent'>


        <Row>

         <Col className='text-center'>
         
         <h1 className="topTitle">  معرض أعمال الفنانة منة الله علاء</h1>
         <h3 className="topSubTitle"> أنت على موعد مع الإحتراف</h3>

         {/* <Button variant="primary">Primary</Button>{' '} */}



         </Col> 
          
        </Row>  



        </Container>





        
        </div>


        </Container>



        </Fragment>
    )
  }
}

export default TopBanner