import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

import SerImg1 from '../../asset/image/Services-1.jpg'
import SerImg2 from '../../asset/image/Services-2.jpg'
import SerImg3 from '../../asset/image/Services-3.jpg'
import SerImg4 from '../../asset/image/Services-4.jpg'


import '../Services/Services.css'
import '../../asset/css/Bootstrap.min.css'

import P1 from'../../asset/image/clinic.jpg'
import{Link} from 'react-router-dom'


 class Services extends Component {
     render() {
          return (
               <Fragment>

                    <Container className="text-center">

                         <h1 className="serviceMainTitle"> خدماتي الأحترافية</h1>
                         <div className="bottom"  ></div>


                         <Row>

                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg1} className="ServicesIcon" />

                                        <h4 className="serviceName"  >رسومات قصص الأطفال</h4>
                                        <p className="serviceDescription">قدرة إبداعية تامه لعمل رسومات قصص الأطفال المصورة مع موهبة إبداعية  لدعم دور النشر بمعرض القاهرة الدولي للكتاب  و غيره     ..</p>

                                        <a href="https://menatullah.art/work/child.html"  rel="noopener noreferrer">
                                        <Button className="bottom1" variant="primary"> تفاصيل  </Button>
                                             </a>
                                   </div>
                              </Col>


                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg2} className="ServicesIcon" />

                                        <h4 className="serviceName"  >الرسم علي الجينز</h4>
                                        <p className="serviceDescription">خليك مميز بين أصحابك و زملائك عن طريق إضافة سماتك الشخصية على ملابسك المميزة .. فقط أتركنا نضع لمساتك على ملبوساتك..</p>
                                       
                                        <a href="https://menatullah.art/work/clothes.html"  rel="noopener noreferrer">
                                        <Button className="bottom1" variant="primary"> تفاصيل  </Button>
                                             </a>                                  
                                   </div>
                              </Col>


                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg3} className="ServicesIcon" />

                                        <h4 className="serviceName"  >تصميم البيزنس كارت</h4>
                                        <p className="serviceDescription">تصاميم إحترافية لشركتك أو نشاطك  مدروسة بعناية فائقة لتخلب العقل وتزيد من ثقة عملاءك وتربطهم بنشاطك بكل مصداقية  ..</p>
                                        <a href="https://menatullah.art/work/cards.html"  rel="noopener noreferrer">
                                        <Button className="bottom1" variant="primary"> تفاصيل  </Button>
                                             </a>  
                                             </div>
                                             </Col>

                              <Col lg={3} md={6} sm={12}  >

                                   <div className="serviceCard  text-center ">

                                        <img src={SerImg4} className="ServicesIcon" />

                                        <h4 className="serviceName"  >تصميم اللوجوهات والبانرز</h4>
                                        <p className="serviceDescription">فقط أعطنا الفكرة... وأترك عناء التصميم الإبداعي للمختصين لذا فإننا نوعدك بتصميم إحترافي  يشرح منتجك دون أن تتكلم عنه ...هذا لأننا نعرف كيف يفكر العميل و المنافس</p>
                                        <a href="https://menatullah.art/work/baners.html"  rel="noopener noreferrer">
                                        <Button className="bottom2" variant="primary"> تفاصيل  </Button>
                                             </a>  
                                   </div>

                              </Col>

                         </Row>

                    </Container>
               </Fragment>
          )
     }
}

export default Services
