import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './Router/AppRouter';
import HomePage from './pages/HomePage'
import TopPage from './pages/TopPage'
import TopNavigation from './components/TopNavigation/TopNavigation'
// import Summary from './components/Summary/Summary'
// import CardsDetails from './components/CardsDetails/CardsDetails'
import AboutPage from './pages/AboutPage'
import ProjectPage from './pages/ProjectPage'
import CardsPage from './pages/CardsPage'
import ChildsPage from './pages/ChildsPage'
import BanersPage from './pages/BanersPage'
import ClothesPage from './pages/ClothesPage'

function App() {
  return(   
  <div>
<TopNavigation/>
<TopPage pagetitle=''/>
{/* <AboutPage/>  */}
 <BrowserRouter>
 <AppRouter />
</BrowserRouter>

{/* <Summary/> */}
{/* <HomePage/> */}
{/* <ProjectPage/> */}
{/* <ProjectDetails/> */}
{/* <CardsPage/> */}
{/* <ChildsPage/> */}
{/* <BanersPage/> */}
{/* <ClothesPage/> */}



</div>

  );
}

export default App;
