import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export class AboutDescreption extends Component {
     render() {
          return (
               <Fragment>
                    <Container className="mt-5">
                         <Row>
                              <Col sm={12} lg={12} md={12}>
                                   <h1 >Who I Am </h1>
                                   <hr />
                                   <p>
                                        Hi! I'm Nizar Damiriyye. I'm a Computer Engineer & developer with a serious love for teaching I am a founder of Cearive Team Programming (C.T.P) and a Programmer & Instructor.
                                        <br></br>
                                        I am working online for the last 5 years and have created several successful websites running on the internet. I try to create a project-based course that helps you to learn professionally and make you fell as a complete developer. Cearive Team Programming exists to help you succeed in life.
                                        <br></br>
                                        Each course has been hand-tailored to teach a specific skill. I hope you agree! Whether you’re trying to learn a new skill from scratch or want to refresh your memory on something you’ve learned in the past, you’ve come to the right place.
                                   </p>

                                   <br></br>



                                   <h1 >Who I Am </h1>
                                   <hr />
                                   <p>
                                        Hi! I'm Nizar Damiriyye. I'm a Computer Engineer & developer with a serious love for teaching I am a founder of Cearive Team Programming (C.T.P) and a Programmer & Instructor.
                                        <br></br>
                                        I am working online for the last 5 years and have created several successful websites running on the internet. I try to create a project-based course that helps you to learn professionally and make you fell as a complete developer. Cearive Team Programming exists to help you succeed in life.
                                        <br></br>
                                        Each course has been hand-tailored to teach a specific skill. I hope you agree! Whether you’re trying to learn a new skill from scratch or want to refresh your memory on something you’ve learned in the past, you’ve come to the right place.
                                   </p>

                              </Col>

                         </Row>
                    </Container>



               </Fragment>

          )
     }
}

export default AboutDescreption
