import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../Footer/Footer.css'
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import {faMap} from '@fortawesome/free-solid-svg-icons'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faPhone} from '@fortawesome/free-solid-svg-icons'

import '../../asset/css/Bootstrap.min.css'




 class Footer extends Component {
     render() {
          return (
               <Fragment>
                    <Container fluid={true} className="footerSection"  >
                         <Row>

                              <Col lg={4} md={6} sm={12} className="text-center p-2" >
                                   <h4 className="footerName"  >Follow Us</h4>
                               <div className="social-container">

                                   <a className="social iconcolor " href="https://www.facebook.com/Mennatullah289?mibextid=LQQJ4d"><FontAwesomeIcon icon={faFacebook}  size='2x' /></a>
                                   <a className="social iconcolor" href="#"><FontAwesomeIcon icon={faYoutube}   size='2x'/></a>
                                   <a className="social iconcolor" href="#"><FontAwesomeIcon icon={faLinkedin}  size='2x'/></a>
                                   <a className="social iconcolor" href="#"><FontAwesomeIcon icon={faTwitter}   size='2x'/></a>
                                   <a className="social iconcolor" href="https://www.instagram.com/mennatullah29_/"><FontAwesomeIcon icon={faInstagram} size='2x' /></a>
                                 </div>
                              </Col>


<Col lg={4} md={6} sm={12} className="p-2" > <h4 className="footerName " >Contact Me</h4>

     <p className="footerDescription" >

     <FontAwesomeIcon icon={faWhatsapp}  /> Wats : +20 111 566 8153  <br></br>

     <FontAwesomeIcon icon={faEnvelope}  /> mtallah28@gmail.com <br></br>

     <FontAwesomeIcon icon={faPhone}  />  Phone : +20 111 566 8153<br></br>

     <FontAwesomeIcon icon={faMap}  />   Giza,Egypt.
     </p>

</Col>
                              <Col lg={4} md={6} sm={12} className="p-2 text-justify "   >
                                   <h4 className="footerName  ">Information</h4>

                                   <a className="footerLink" href="">About Me</a> <br></br>
                                   <a className="footerLink" href="">My Profile</a> <br></br>
                                   <a className="footerLink" href="">Contact Me</a> <br></br>


                              </Col>



                         </Row>

                         <center> 
                         <a className="whatsapp" href="https://api.whatsapp.com/send?phone=201115668153&text= مرحبا أ/ منه : أحتاج الى التواصل بشأن بعض المنتجات" > WhatsApp</a>
                         </center>
                         <br></br>
                        

                    </Container>
               </Fragment>

          )
     }
}
export default Footer
